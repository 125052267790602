<template>
<div id="app">
    <router-view />
</div>
</template>

<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/9092818.js"></script>
<script>
export default {

}
</script>
